<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationControlCurr operationControlWidth"
          style="align-items: flex-start;"
        >
          <div class="searchbox" style="width:100%">
            <div>
              <div title="班级编码" class="searchboxItem searchboxItems">
                <span class="itemLabel">班级编码 :</span>
                <el-input
                  v-model="projectCode"
                  type="text"
                  size="small"
                  placeholder="请输入班级编码 "
                  onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                  clearable
                />
              </div>
              <div title="班级名称" class="searchboxItem searchboxItems">
                <span class="itemLabel" style="min-width:6rem">班级名称:</span>
                <el-input
                  v-model="projectName"
                  type="text"
                  size="small"
                  placeholder="请输入班级名称"
                  clearable
                  style="flex:1"
                />
              </div>
              <div class="df">
                <transition name="el-zoom-in-center">
                  <el-button
                    type="primary"
                    class="bgc-bv"
                    round
                    @click="getData()"
                    v-if="flag == false"
                  >查询</el-button>
                </transition>
              </div>
              <div style="margin-left:10px;">
                <el-button @click="search()" size="small" type="text">
                  高级搜索
                  <i :class="flag ? 'el-icon-top' : 'el-icon-bottom'"></i>
                </el-button>
              </div>
            </div>
            <div class="btnBox">
              <el-button type="primary" class="bgc-bv" round @click="addTestPaper">新建班级</el-button>
              <el-button class="bgc-bv" round @click="open" size="small">自定义表头</el-button>
            </div>
          </div>
          <el-collapse-transition>
            <div>
              <div class="searchbox" style="margin-top:10px;width:100%" v-if="flag == true">
                <div class="searchbox">
                  <div title="负责人" class="searchboxItem">
                    <span class="itemLabel">负责人 :</span>
                    <el-input
                      v-model="ruleForm.projectUser"
                      type="text"
                      size="small"
                      placeholder="请输入负责人"
                      clearable
                      style="width: auto;"
                    />
                  </div>
                  <div title="班级状态" class="searchboxItem">
                    <span class="itemLabel" style="min-width:6rem">班级状态:</span>
                    <el-select
                      size="small"
                      v-model="ruleForm.Classstatus"
                      placeholder="请选择班级状态"
                      clearable
                    >
                      <el-option
                        v-for="item in ClassstatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                  <div title="行政区划" class="searchboxItem">
                    <span class="itemLabel">行政区划:</span>
                    <el-cascader
                      clearable
                      filterable
                      v-model="ruleForm.areaId"
                      :options="areatreeList"
                      :props="propsarea"
                      size="small"
                    ></el-cascader>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-transition>
          <el-collapse-transition>
            <div class="searchbox" style="margin-top:10px;width:100%" v-show="flag == true">
              <div class="searchbox">
                <div title="培训类型" class="searchboxItem">
                  <span class="itemLabel">培训类型:</span>
                  <tree
                    ref="tree"
                    @eventBtn="childBack"
                    :ruleForm="ruleForm"
                    size="small"
                    typeStu
                    @clearParams="clearParams"
                  />
                </div>
                 <div title="负责人电话" class="searchboxItem">
                    <span class="itemLabel projectUser">负责人电话:</span>
                    <el-input
                      v-model="ruleForm.projectUserPhone"
                      type="text"
                      size="small"
                      placeholder="请输入负责人电话"
                      clearable
                    />
                  </div>
                <div title="开班时间" class="searchboxItem">
                  <span class="itemLabel">开班时间:</span>
                  <el-date-picker
                    v-model="ruleForm.opentime"
                    type="daterange"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="timestamp"
                    clearable
                  />
                </div>
              </div>
            </div>
          </el-collapse-transition>
          <el-collapse-transition>
            <div class="searchbox" style="margin-top:10px;width:100%" v-show="flag == true">
              <div class="searchbox">
                <div title="结束时间" class="searchboxItem">
                  <span class="itemLabel">结束时间:</span>
                  <el-date-picker
                    v-model="ruleForm.endDate"
                    type="daterange"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    value="yyyy-MM-dd"
                    clearable
                  />
                </div>
                <div class="df">
                  <el-button type="primary" class="bgc-bv" round @click="getsearch">查询</el-button>
                </div>
                <div class="df" style="margin-left:10px;">
                  <el-button type="text" round @click="resetForm">清空搜索条件</el-button>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
        <div style="margin: 15px 0 5px">
          <span>班级总数：{{ projectNum }}</span>
          <span style="margin:0 30px;">班级总人次：{{ projectPeopleNum }}</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              v-if="showtable"
            >
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
              <el-table-column
                label="班级编码"
                align="center"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200px"
              />
              <el-table-column
                label="班级名称"
                align="center"
                show-overflow-tooltip
                prop="projectName"
                min-width="250px"
              />
              <!-- <template v-if="!tableHaed.length">
                <el-table-column
                  label="行政区划"
                  align="center"
                  show-overflow-tooltip
                  prop="areaNamePath"
                  width="150px"
                />
                <el-table-column
                  label="培训类型"
                  align="center"
                  show-overflow-tooltip
                  prop="trainTypeNamePath"
                  width="120px"
                />
                <el-table-column
                  label="负责人"
                  align="center"
                  show-overflow-tooltip
                  prop="projectUser"
                />
                <el-table-column
                  label="负责人电话"
                  align="center"
                  show-overflow-tooltip
                  prop="projectUserPhone"
                  width="120px"
                />
                <el-table-column
                  label="开始时间"
                  align="center"
                  show-overflow-tooltip
                  prop="startDate"
                  width="120px"
                >
                  <template slot-scope="scope">
                    {{
                    scope.row.startDate | momentDate
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="结束时间" align="center" show-overflow-tooltip width="120px">
                  <template slot-scope="scope">
                    {{
                    scope.row.endDate | momentDate
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="报名截止" align="center" show-overflow-tooltip width="120px">
                  <template slot-scope="scope">
                    {{
                    scope.row.signEndDate | momentDate
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="班级状态"
                  align="center"
                  show-overflow-tooltip
                  prop="auditState"
                  width="100px"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="
                      scope.row.auditState == '10' ||
                        scope.row.auditState == '20' ||
                        scope.row.auditState == '40'
                    "
                    >
                      {{
                      $setDictionary("AUDITSTATE", scope.row.auditState) ==
                      "未提审"
                      ? "未申请"
                      : $setDictionary("AUDITSTATE", scope.row.auditState)
                      }}
                    </span>

                    <span
                      v-else-if="
                      scope.row.auditState == '30' &&
                        scope.row.projectStudyTimeState != '10'
                    "
                    >
                      {{
                      $setDictionary(
                      "PROJECTSTATE",
                      scope.row.projectStudyTimeState
                      )
                      }}
                    </span>
                    <span
                      v-else-if="scope.row.auditState == '30'"
                    >{{ $setDictionary("AUDITSTATE", scope.row.auditState) }}</span>
                    <span v-else-if="scope.row.auditState == '25'">待审核</span>
                    <span v-else-if="scope.row.auditState == '45'">审核未通过</span>
                  </template>
                </el-table-column>
                <el-table-column label="岗位类型" align="center" show-overflow-tooltip prop="postName">
                  <template slot-scope="scope">
                    {{
                    scope.row.postName || "--"
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="行业类型"
                  align="center"
                  show-overflow-tooltip
                  prop="industryNamePath"
                >
                  <template slot-scope="scope">
                    {{
                    scope.row.industryNamePath || "--"
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="职业/工种"
                  align="center"
                  show-overflow-tooltip
                  prop="occupationNamePath"
                  width="200"
                >
                  <template slot-scope="scope">
                    {{
                    scope.row.occupationNamePath || "--"
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="培训等级"
                  align="center"
                  show-overflow-tooltip
                  prop="trainLevelName"
                >
                  <template slot-scope="scope">
                    {{
                    scope.row.trainLevelName || "--"
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="班级总人数"
                  align="center"
                  show-overflow-tooltip
                  prop="projectPeopleNum"
                  width="140px"
                />

                <el-table-column
                  label="课程总人次"
                  align="center"
                  show-overflow-tooltip
                  prop="projectCourseNum"
                  width="140px"
                />
              </template>-->
              <template v-if="tableHaed.length">
                <el-table-column
                  v-for="(el,index) in tableHaed"
                  :key="index"
                  :label="el.label"
                  :align="el.textAlign"
                  show-overflow-tooltip
                  :min-width="el.width"
                  :fixed="el.label=='试卷名称'"
                >
                  <template slot-scope="scope">
                    {{
                    getValue(el,scope.row)
                    }}
                  </template>
                </el-table-column>
              </template>
              <el-table-column label="操作" align="center" width="270px" fixed="right">
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="seeDetails(scope.row)"
                  >详情</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="jumpclassList(scope.row)"
                  >课程列表</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="jumpStudentList(scope.row)"
                  >学员列表</el-button>
                  <el-button
                    type="text"
                    style="padding:0 5px"
                    @click="
                      payOrder(scope.row.projectId, scope.row.projectName)
                    "
                    :disabled="
                      scope.row.courseCount == 0 ||
                        scope.row.projectSource == '20'
                    "
                    size="mini"
                  >班级二维码</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="deleteproject(scope.row.projectId)"
                    :disabled="
                      scope.row.auditState == '20' ||
                        scope.row.auditState == '30' ||
                        scope.row.projectSource == '20'
                    "
                  >删除</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="
                      keeprecord(scope.row)
                    "
                    :disabled="
                      scope.row.auditState == '20' ||scope.row.auditState == '25' ||
                        scope.row.auditState == '30'
                    "
                  >开班申请</el-button>

                  <el-button
                    type="text"
                    style="padding:0 5px"
                    size="mini"
                    @click="showDocsDialog(scope.row)"
                    :disabled="scope.row.projectStudyTimeState == '10'"
                  >培训档案</el-button>
                  <el-button
                    type="text"
                    style="padding:0 5px;"
                    @click="classDaily(scope.row.projectId)"
                    size="mini"
                  >班级日报码</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 班级日报 -->
    <el-dialog
      width="800px"
      title="班级日报"
      :modal="innerClassDailyModel"
      :visible.sync="innerClassDaily"
      @close="innerClassDailyClose"
    >
      <div>
        <div style="display:flex">
          <div class="leftData">
            <img :src="classImg" alt />
            <span>该二维码24小时内（{{ this.Spare }}前）有效</span>
          </div>
          <div class="dailyRemind">
            <div class="dailyRemind-1">
              <h3>微信扫码订阅班级日报</h3>
              <p>微信可接收该班级的学员日报并可向学员推送消息</p>
            </div>
            <div class="dailyRemind-2">
              <h3>订阅日报步骤</h3>
              <p>1· 订阅日报人员打开微信扫一扫，扫描左侧二维码。</p>
              <p>2· 扫描后关注“安知平台”公众号。</p>
              <p>3· 关注后点击【立即绑定】，填写身份证号、姓名和手机号进行绑定。</p>
              <p>4· 已经绑定的用户，点击【立即查看】，查看班级日报。</p>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div>
          <h3 style="margin-bottom:20px">订阅班级日报人员</h3>
          <el-table
            ref="multipleTable"
            :data="tableClassData"
            height="200px"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="姓名"
              align="left"
              show-overflow-tooltip
              prop="userName"
              min-width="150px"
            />
            <el-table-column
              label="手机"
              align="left"
              show-overflow-tooltip
              prop="mobile"
              min-width="150px"
            />
            <el-table-column label="操作" align="center" width="270px" fixed="right">
              <div slot-scope="scope">
                <el-button
                  type="text"
                  style="padding:0 5px"
                  @click="classDailyClose(scope.row.subId, scope.row.userName)"
                  size="mini"
                >解绑</el-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <el-dialog width="500px" :title="innerTitle" @close="closeCode" :visible.sync="innerVisible">
      <div class="qrcode">
        <!-- 放置二维码的容器,需要给一个ref -->
        <div id="qrcode" ref="qrcode"></div>
      </div>
      <div class="refreshcode">
        <el-button class="bgc-bv" @click="refreshCode">重新生成班级二维码</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="培训档案选择"
      :visible.sync="docsDialogVisible"
      width="50%"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div >
        <div class="ovy-a">
          <div class="downloadStyle">
            <!-- <el-form ref="docsForm" :model="docsForm" class="docsDialog">
              <el-form-item v-for="(val, key) in docsOptions" :key="key">
                <el-checkbox v-model="docsForm[key]">{{ val }}</el-checkbox>
              </el-form-item>
            </el-form>
            <el-form
              ref="docsForms"
              :model="docsForm"
              class="docsDialog"
              v-show="trainTypeId == 5 && dayTure != false"
            >
              <el-form-item v-for="(val, key) in docsOptionss" :key="key">
                <el-checkbox v-model="docsForms[key]">{{ val }}</el-checkbox>
              </el-form-item>
            </el-form>
            <el-form
              ref="docsFormall"
              :model="docsFormall"
              class="docsDialog"
              v-show="trainTypeId == 11"
            >
              <el-form-item v-for="(val, key) in docsOptionall" :key="key">
                <el-checkbox v-model="docsFormall[key]">{{ val }}</el-checkbox>
              </el-form-item>
            </el-form> -->
             <el-form
              ref="docsFormnew"
              :model="docsFormnew"
              class="docsDialog"
              v-show="danganList.length"
            >
              <el-form-item v-for="(item, index) in danganList" :key="index">
                <el-checkbox v-model="docsFormnew[item.dictCode]">{{
                  item.dictValue
                }}</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <el-checkbox v-model="agreeState" style="margin: 0 3%;">
            <span style="color:red!important;">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center;">
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="自定义表头"
      :visible.sync="tableHeadDialog"
      width="30%"
      class="custom"
      @close="cencal"
    >
      <p style="padding-left:20px;margin-bottom:10px;">勾选需要显示在列表上的项，可拖动调整顺序</p>
      <div>
        <el-tree
          ref="headTree"
          :data="treeDataCopy"
          default-expand-all
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
        >
          <template slot-scope="{node,data}">
            <el-checkbox
              @change="(val)=>{handleCheckChange(val,data)}"
              v-model="data.checked"
              :disabled="data.label == '课程名称'"
            >{{node.label}}</el-checkbox>
          </template>
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencal">取 消</el-button>
        <el-button class="bgc-bv" @click="resetHeadOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import QRCode from "qrcodejs2"; // 引入qrcode
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "sjz/jg/classList",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      innerClassDaily: false,
      innerClassDailyModel: true,
      classImg: "", //二维码地址
      tableClassData: [], //班级日报，弹框table数据
      projectIdSel: "", //选择的班级ID

      showtable: false,
      innerVisible: false,
      innerTitle: "",
      // dialogVisible: false,
      areatreeList: [],
      ClassstatusList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      projectName: "",
      projectCode: "",
      ruleForm: {
        areaId: "",
        projectUser: "",
        projectUserPhone: "",
        Classstatus: "",
        opentime: "",
        Trainingtype: ""
      },
      docsForm: {},
      docsForms: {},
      docsFormall: {},
      docsDialogVisible: false,
      agreeState: false, //弹框是否同意
      selectedItem: {},
      docsOptions: {
        "20": "学员培训档案",
        "40": "培训证书",
        "70": "学员统计情况总表"
      },
      docsOptionss: {
        "50": "职业提升培训线上学习情况审核"
      },
      docsOptionall: {
        "10": "全员培训档案首页",
        "15": "生产经营单位新入职从业人员登记表",
        "20": "厂（矿）级安全培训考核记录",
        "25": "车间（工段、区、队）级安全培训考核记录",
        "30": "班组级安全培训考核记录",
        "35": "一般行业生产经营单位新入职从业人员安全培训考核记录",
        "40": "生产经营单位从业人员年度安全再培训考核记录",
        "45": "生产经营单位从业人员调岗和重新上岗安全培训考核记录(车间级)",
        "50": "生产经营单位从业人员调岗和重新上岗安全培训考核记录(班组级)",
        "55": "生产经营单位从业人员“四新”安全培训考核记录",
        "60": "生产经营单位从业人员专项安全培训考核记录",
        "65": "特种作业人员培训信息登记及证件真伪核查表",
        "70": "生产经营单位从业人员安全生产奖惩记录",
        "1000": "全员培训档案模版"
      },
      params: {},
      projectNum: "", //班级总数：
      projectPeopleNum: "", //班级总人次：
      dayTure: false,
      flag: false,
      // 自定义表头
      tableHeadDialog: false, //
      treeData: [
        {
          label: "行政区划", //表头名称
          propName: "areaNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 1,
          id: "1",
          checked: true
        },
        {
          label: "培训类型", //表头名称
          propName: "trainTypeNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 2,
          id: "2",
          checked: true
        },
        {
          label: "行业类型", //表头名称
          propName: "industryNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 3,
          id: "3",
          checked: true
        },
        {
          label: "岗位类型", //表头名称
          propName: "postName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 4,
          id: "4",
          checked: true
        },
        {
          label: "职业/工种", //表头名称
          propName: "occupationNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 5,
          id: "5",
          checked: true
        },
        {
          label: "培训等级", //表头名称
          propName: "trainLevelName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 6,
          id: "6",
          checked: true
        },
        {
          label: "负责人", //表头名称
          propName: "projectUser", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 7,
          id: "7",
          checked: true
        },
        {
          label: "负责人电话", //表头名称
          propName: "projectUserPhone", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 8,
          id: "8",
          checked: true
        },
        {
          label: "开始时间", //表头名称
          propName: "startDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 9,
          id: "9",
          checked: true
        },
        {
          label: "结束时间", //表头名称
          propName: "endDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 10,
          id: "10",
          checked: true
        },
        {
          label: "报名截止", //表头名称
          propName: "signEndDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 11,
          id: "11",
          checked: true
        },
        {
          label: "班级状态", //表头名称
          propName: "projectStudyTimeState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "AUDITSTATE", //字典对应key
          width: "120", // 列的宽
          order: 12,
          id: "12",
          checked: true
        },
        {
          label: "班级总人数", //表头名称
          propName: "projectPeopleNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "140", // 列的宽
          order: 13,
          id: "13",
          checked: true
        },
        {
          label: "课程总人次", //表头名称
          propName: "projectCourseNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "140", // 列的宽
          order: 14,
          id: "14",
          checked: true
        }
      ],
      tableHaed: [],
      treeDataCopy: [],
      danganList:[], //培训档案数据
      docsFormnew:{},//档案数据绑定值
      
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser"
    })
  },
  mounted() {
    this.getTableHeight();
    this.getareatree();
    this.getclassstatusList();
    this.getTableHead();
  },
  methods: {
    // 关闭日报弹窗
    innerClassDailyClose(){
      this.innerClassDaily = false;
      this.innerClassDailyModel = false;
    },
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        if (el.propName == "projectStudyTimeState") {
          if (
            row.auditState == "10" ||
            row.auditState == "20" ||
            row.auditState == "40"
          ) {
            return this.$setDictionary("AUDITSTATE", row.auditState) == "未提审"
              ? "未申请"
              : this.$setDictionary("AUDITSTATE", row.auditState) ==
                "审核未通过"
              ? "未通过"
              : this.$setDictionary("AUDITSTATE", row.auditState);
          } else if (
            row.auditState == "30" &&
            row.projectStudyTimeState != "10"
          ) {
            return this.$setDictionary(
              "PROJECTSTATE",
              row.projectStudyTimeState
            ) == "审核未通过"
              ? "未通过"
              : this.$setDictionary("PROJECTSTATE", row.projectStudyTimeState);
          } else if (row.auditState == "30") {
            return this.$setDictionary("AUDITSTATE", row.auditState);
          } else {
            return this.$setDictionary(el.dictionaries, row[el.propName]);
          }
        } else {
          return this.$setDictionary(el.dictionaries, row[el.propName]);
        }
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss")
            : "--";
        } else if (el.timeType == "momentDate") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD")
            : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else {
        // 其他
        return row[el.propName] || "--";
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHaed = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "classListGL"
      }).then(res => {
        this.getTableHead();
      });
    },
    open() {
      this.tableHeadDialog = true;
      this.handle();
    },
    cencal() {
      this.tableHeadDialog = false;
    },
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "classListGL"
      })
        .then(res => {
          if (res.status == 0) {
            const data = res.data || {};
            let list = [];
            if (data.configInfo && data.configInfo != "") {
              list = JSON.parse(data.configInfo);
            }
            if (list.length) {
              list.map(el => {
                this.treeData.forEach((it, index) => {
                  if (el.id == it.id) {
                    it.checked = el.checked;
                    it.order = el.order;
                  }
                });
              });
              this.treeData.sort((a, b) => {
                return a.order - b.order;
              });
              this.handle();
            } else {
              this.handle();
            }
          } else {
            // this.tableHaed = [...this.treeData];
            this.handle();
          }
        })
        .catch(err => {
          this.tableHaed = [...this.treeData];
        });
    },
    handle() {
      this.tableHaed = [];
      this.treeDataCopy = [];
      this.treeData.forEach(el => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      this.treeDataCopy.forEach(el => {
        if (el.checked) {
          this.tableHaed.push(el);
        }
      });
      this.showtable = true;
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      if (draggingNode.label == "课程名称") {
        return false;
      }
      return true;
    },
    handleCheckChange(val, data) {},
    /**
     *
     */
    //获取班级总数和班级总人次
    getTotal(pageNum = 1) {
      const params = {
        // compId:'',
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.projectUser) {
        params.projectUser = this.ruleForm.projectUser;
      }
      if (this.ruleForm.projectUserPhone) {
        params.projectUserPhone = this.ruleForm.projectUserPhone;
      }
      if (this.ruleForm.Classstatus) {
        params.projectStudyTimeState = this.ruleForm.Classstatus;
      }
      if (this.ruleForm.opentime) {
        params.startDateLong = this.ruleForm.opentime[0];
        params.endDateLong = this.ruleForm.opentime[1];
      }
      if (this.ruleForm.endDate) {
        params.endDateStart = this.ruleForm.endDate[0];
        params.endDateEnd = this.ruleForm.endDate[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.$post("/sjz/project/queryProjectTotalAndPeopleTotal", {
        ...params,
        pageNum,
      },false).then(ret => {
        this.projectNum = ret.data.projectNum;
        this.projectPeopleNum = ret.data.projectPeopleNum;
      });
    },
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      this.getTotal();
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.projectUser) {
        params.projectUser = this.ruleForm.projectUser;
      }
      if (this.ruleForm.projectUserPhone) {
        params.projectUserPhone = this.ruleForm.projectUserPhone;
      }
      if (this.ruleForm.Classstatus) {
        params.projectStudyTimeState = this.ruleForm.Classstatus;
      }
      if (this.ruleForm.opentime) {
        params.startDateLong = this.ruleForm.opentime[0];
        params.endDateLong = this.ruleForm.opentime[1];
      }
      if (this.ruleForm.endDate) {
        params.endDateStart = this.ruleForm.endDate[0];
        params.endDateEnd = this.ruleForm.endDate[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/sjz/project/listPage",
        params,
        pageNum
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key]
        });
      }
      this.ClassstatusList = list;
    },
    //删除
    deleteproject(projectId) {
      this.doDel({
        url: "/biz/project/delete",
        msg: "你确定要删除该班级吗？",
        ps: {
          type: "post",
          data: { projectId }
        }
      });
    },
    //设置缓存
    setStorage(row, params) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("areaId", row.areaId);
      sessionStorage.setItem("auditState", row.auditState);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("trainTypeId", row.trainTypeId);
      sessionStorage.setItem("projectSource", row.projectSource);
      sessionStorage.setItem("cClessonNum", row.lessonNum);
      sessionStorage.setItem("signEndDateLong", row.signEndDate);
      this.$router.push(params);
    },
    //详情
    seeDetails(row) {
      this.setStorage(row, {
        path: "/web/ClassdetailsList",
        query: {
          active: "first",
        },
      });
    },
    //班级列表
    jumpclassList(row) {
      this.setStorage(row, {
        path: "/web/ClassdetailsList",
        query: {
          active: "second",
          sjz: true
        }
      });
    },
    //跳转学员列表
    jumpStudentList(row) {
      this.setStorage(row, {
        path: "/web/ClassdetailsList",
        query: {
          active: "three",
          sjz: true
        }
      });
    },
    //开班申请
    keeprecord(row) {
      if (Number(row.courseCount) < 1) {
        this.$confirm("你的班级还未配置课程，请先为班级配课", "提示", {
          confirmButtonText: "班级配课",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.jumpclassList(row);
          })
          .catch(() => {
            return;
          });
      } else if (Number(row.projectPeople) != Number(row.trainPeople)) {
        this.$confirm(
          "该班级学员总人数与计划培训人数不符。",
          "提示",
          {
            confirmButtonText: "学员列表",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            this.jumpStudentList(row);
          })
          .catch(() => {
            return;
          });
      } else {
        //点名分配页面
        this.$router.push({
          path: "/web/sjz/jg/RollCallSet",
          query: {
            projectId: row.projectId,
            showDialog: true
          }
        });
      }
    },
    // 展示二维码
    payOrder(projectId, projectName) {
      this.innerVisible = true;
      this.innerTitle = projectName;
      this.projectId = projectId;
      this.$post("/biz/project/apply/key", { projectId: projectId }).then(
        ret => {
          this.qrcode = ret.data;
          // 使用$nextTick确保数据渲染
          this.$nextTick(() => {
            this.crateQrcode();
          });
        }
      );
    },
    // 生成二维码
    crateQrcode() {
      this.$refs.qrcode.innerHTML = "";
      this.qr = new QRCode("qrcode", {
        width: 300,
        height: 300, // 高度
        text: this.qrcode, // 二维码内容
        render: "canvas" // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      });
    },
    /* 刷新二维码 */
    refreshCode() {
      this.$confirm(
        "重新生成后,以前的二维码将失效,你确定要重新生成吗？",
        "提示",
        {
          confirmButtonText: "确定",
          confirmButtonClass: "bgc-bv",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          this.$post("/biz/project/reset/key", {
            projectId: this.projectId
          })
            .then(ret => {
              this.qrcode = ret.data;
              this.$message({
                type: "success",
                message: "二维码已重新生成"
              });
              // 使用$nextTick确保数据渲染
              this.$nextTick(() => {
                this.crateQrcode();
              });
            })
            .catch(err => {
              return;
            });
        })
        .catch(() => {
          return;
        });
    },
    // 关闭弹框,清除已经生成的二维码
    closeCode() {
      this.$refs.qrcode.innerHTML = "";
    },
    // 新增 编辑
    addTestPaper() {
      this.$router.push({
        path: "/web/addClassCtr",
        query: {
          projectId: null,
          stu: "add",
          projectSource: 30
        }
      });
    },
    /* 高级搜索 */
    getsearch() {
      this.getData();
      // this.dialogVisible = false;
      // this.$refs['ruleForm'].resetFields();
    },
    // 高级搜索弹窗
    search() {
      this.flag = !this.flag;
      this.params = {};
      this.getTableHeight();
    },
    //清空搜索条件
    resetForm() {
      // this.$refs[formName].resetFields();
      this.params = {};
      this.ruleForm = {};
      // this.flag = true;
      this.projectCode = "";
      this.projectName = "";
      // this.getTableHeight()
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.flag == false ? 80 + 0.675 * 16 + 6 : 195 + 0.675 * 16 + 6;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    //获取档案列表数据
       getList(id) {
      this.$post("/run/project/archives", { projectId: id }).then((res) => {
        this.danganList = res.data;
      });
    },
   
     closeDocsDialog() {
      this.docsDialogVisible = false;
      this.agreeState = false;
      this.$refs["docsFormnew"].resetFields();
      for (let key in this.docsFormnew) {
        this.docsFormnew[key] = false;
      }
    },
    showDocsDialog(raw) {
      this.docsDialogVisible = true;
      this.selectedItem.id = raw.projectId;
      this.selectedItem.name = raw.projectName;
      this.trainTypeId = raw.trainTypeId;
      this.getList(raw.projectId);
      this.day = raw.endDate;
      let day = this.day.slice(0, 10);
      let curDay = this.$moment().format("YYYY/MM/DD");
      if (curDay > day) {
        this.dayTure = true;
      }
    },
    confirmDocsDialog() {
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意"
        });
        return;
      }
      let params = {
        fileDTOS: []
      };
      for (let i in this.docsFormnew) {
        if (this.docsFormnew[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName:
              this.selectedItem.name +
              "-" +
              this.danganList.find((el) => el.dictCode == i).dictValue,
            className: className,
            param: {
              projectId: this.selectedItem.id,
            },
          });
        }
      }
      if (!params.fileDTOS.length) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }
      // if (!params.fileDTOSAll.length) {
      //   this.$message.error("请至少选择一项进行打印");
      //   return;
      // }

      this.$post("/sys/download/asyncDownloadFile", params).then(re => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(re.message);
        }
      });
    },
    classDaily(id) {
      this.classImg = "";
      this.projectIdSel = id;
      this.$post("/biz/subscribeProject/createSubscribeProjectQRCode", {
        projectId: id
      }).then(re => {
        if (re.status == "0") {
          this.classImg = re.data;
        }
      });
      this.$post("/biz/subscribeProject/querySingleSubscribeProjectList", {
        projectId: id
      }).then(re => {
        if (re.status == "0") {
          this.tableClassData = re.data;
        } else {
          this.tableClassData = [];
        }
      });
      this.innerClassDaily = true;
      this.innerClassDailyModel = true;
      var day3 = new Date();
      //获取当前时间戳
      // var timestamp=new Date().getTime()；
      // console.log(day3-86400000)
      // var day2 = day3-86400000
      // 设置默认时间——先转化为毫秒数，加上 24 小时的毫秒数，再转化回来
      // function setTime () {
      let t = new Date().getTime() + 24 * 60 * 60 * 1000; //24小时 * 60分钟 * 60秒 * 1000
      let d = new Date(t);
      let theMonth = d.getMonth() + 1;
      let theDate = d.getDate();
      let theHours = d.getHours();
      let theMinutes = d.getMinutes();
      let theSec = d.getSeconds();
      if (theMonth < 10) {
        theMonth = "0" + theMonth;
      }
      if (theDate < 10) {
        theDate = "0" + theDate;
      }
      if (theHours < 10) {
        theHours = "0" + theHours;
      }
      if (theMinutes < 10) {
        theMinutes = "0" + theMinutes;
      }
      if (theSec < 10) {
        theSec = "0" + theSec;
      }
      let date = d.getFullYear() + "年" + theMonth + "月" + theDate + "日";
      let time = theHours + ":" + theMinutes + ":" + theSec;
      let Spare = date + " " + time;

      this.Spare = Spare;
      // }s
      // setTime()
    },
    classDailyClose(subId, name) {
      this.$post("/biz/subscribeProject/deleteSingleSubscribeProject", {
        subId: subId
      }).then(re => {
        if (re.status == "0") {
          this.$message({
            message: name + "解绑成功",
            type: "success"
          });
          this.$post("/biz/subscribeProject/querySingleSubscribeProjectList", {
            projectId: this.projectIdSel
          }).then(re => {
            if (re.status == "0") {
              this.tableClassData = re.data;
            } else {
              this.tableClassData = [];
            }
          });
        } else {
          this.innerClassDaily = false;
          this.innerClassDailyModel = false;
        }
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: function(route) {
      if(route.name !='sjz/jg/classList') return;
      if (route.params.refresh) {
        if (route.params.isAdd) {
          this.projectName = "";
          this.projectCode = "";
          this.ruleForm = {
            areaId: "",
            projectUser: "",
            projectUserPhone: "",
            Classstatus: "",
            opentime: "",
            Trainingtype: ""
          };
          this.params = {};
          this.getData();
          if (route.params.addId) {
            this.classDaily(route.params.addId);
          }
        } else {
          this.getData(-1);
        }
      } else if (sessionStorage.getItem("refresh") != 0) {
        sessionStorage.setItem("refresh", 0);
        this.projectName = "";
        this.projectCode = "";
        this.ruleForm = {
          areaId: "",
          projectUser: "",
          projectUserPhone: "",
          Classstatus: "",
          opentime: "",
          Trainingtype: ""
        };
        this.params = {};
        this.getData();
      } else {
        this.getData(-1);
      }
      // this.activeName = route.params.refName;
    }
  }
};
</script>
<style lang="less" scoped>
.leftData {
  display: flex;
  flex-direction: column;
  img {
    width: 300px;
    height: 300px;
  }
  span {
    display: flex;
    width: 300px;
  }
}
.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;
  .dailyRemind-1 {
    margin-bottom: 20px;
    p {
      margin-top: 10px;
    }
  }
  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: none;
}
.projectUser {
  min-width: 6rem !important;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}

.operationControl {
  input {
    width: auto;
  }
}
</style>
